'use client';
import { IAuthLoginPayload } from '@truckmap/common';
import React, { useEffect, useRef, useState } from 'react';

import { AuthFormContent } from 'components/Auth/AuthForm/AuthFormContent';
import { AuthModalProps } from '../AuthModal/AuthModal';
import { AuthFormContext, AuthFormSteps } from './AuthFormContext';
import { AuthPhoneStepProps } from './AuthPhoneStep';

export type AuthPhoneFormProps = {
  hasPhoneModal?: boolean;
  showPhoneModal?: boolean;
  onClosePhoneModal?: GenericHandler;
} & Pick<AuthModalProps, 'size'>;

export type AuthConfirmCodeFormProps = {
  hasConfirmCodeModal?: boolean;
  showBackCodeStep?: boolean;
  hidePhoneStepOnConfirmCode?: boolean;
};

export type AuthFormProps = {
  showBackButton?: boolean;
  showClose?: boolean;
  showUsernameForm?: boolean;
} & AuthPhoneStepProps &
  AuthPhoneFormProps &
  AuthConfirmCodeFormProps;

export const AuthForm = React.memo((props: AuthFormProps) => {
  const userCache = useRef<IAuthLoginPayload>();
  const [formStep, setFormStep] = useState<AuthFormSteps>('phone');
  const [showConfirmCodeModal, setConfirmCodeModal] = useState(false);

  useEffect(() => {
    setConfirmCodeModal(formStep === 'code');
  }, [formStep, showConfirmCodeModal]);

  return (
    <AuthFormContext.Provider
      value={{
        setFormStep,
        userCache,
        formStep,
        showConfirmCodeModal
      }}>
      <AuthFormContent {...props} />
    </AuthFormContext.Provider>
  );
});

AuthForm.displayName = 'AuthForm';
