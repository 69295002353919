import { GenericSection } from 'components/contentful/Section/GenericSection';
import { usePageStyles } from 'components/Page/Page';
import React from 'react';
import { IUIComponent } from 'types/generated/contentful';

export type PageUIComponentsProps = ChildrenProp & {
  uiComponents: IUIComponent[];
};

export const PageUIComponents: React.ComponentType<PageUIComponentsProps & ParentClassNameProp> =
  React.memo(({ children, uiComponents = [], className }) => {
    const styles = usePageStyles({ className });
    return (
      <main className={styles.content}>
        {uiComponents.map((entry) => (
          <section key={entry.sys.id} id={`section-${entry.sys.id}`}>
            <GenericSection entry={entry} />
          </section>
        ))}
        {children}
      </main>
    );
  });

PageUIComponents.displayName = 'PageUIComponents';
