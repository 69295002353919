import { Modal, ModalProps } from 'components/common/Modal';
import React from 'react';

import { AuthForm } from '../AuthForm/AuthForm';

export type AuthModalProps = {
  size?: 'sm' | 'lg';
} & Pick<
  ModalProps,
  'open' | 'onClose' | 'showClose' | 'showBack' | 'onBack' | 'hasMobileFullHeight'
> &
  ChildrenProp;

export const AuthModal: React.FC<AuthModalProps> = ({ children, size = 'sm', ...modalProps }) => {
  return (
    <Modal disableOverflowY size={size} {...modalProps}>
      {children ?? <AuthForm />}
    </Modal>
  );
};

AuthModal.displayName = 'AuthModal';
