import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { Divider } from 'components/common/Divider';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import { makeStyles } from 'lib/makeStyles';
import React, { useCallback } from 'react';

import { AuthEmailForm } from '../../../Auth/AuthForm/AuthEmail/AuthEmailForm';
import { GeneralSettingsSubmit } from './GeneralSettingsSubmit';

export type GeneralSettingsProps = ParentClassNameProp & {
  showTitle?: boolean;
  fullWidth?: boolean;
  onSubmit?: GenericHandler;
};

const useStyles = makeStyles<GeneralSettingsProps>()({
  root: (props) => ['w-full', props.className],
  form: ({ fullWidth }) => [{ 'max-w-md': !fullWidth, 'w-full': fullWidth }]
});

export const GeneralSettings: React.ComponentType<GeneralSettingsProps> = React.memo((props) => {
  const { showTitle = true, fullWidth = false } = props;
  const styles = useStyles({ fullWidth, ...props });
  const onSubmitUsername = useCallback(() => {
    globalAlert({
      id: 'settings_submit_username',
      intent: 'success',
      position: 'top-center',
      message: 'User settings saved!',
      hasCloseButton: true
    });
    if (props?.onSubmit) {
      props.onSubmit();
    }
  }, [props.onSubmit]);

  return (
    <div className={styles.root}>
      {showTitle && (
        <>
          <MetaText bold>
            <Translation textKey="GENERAL_SETTINGS" />
          </MetaText>
          <Divider />
        </>
      )}
      <div className={styles.form}>
        <AuthEmailForm onSubmit={onSubmitUsername} fillValues>
          <GeneralSettingsSubmit />
        </AuthEmailForm>
      </div>
    </div>
  );
});

GeneralSettings.displayName = 'GeneralSettings';
