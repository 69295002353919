'use client';
import { IAuthLoginPayload } from '@truckmap/common';
import { createContext, useContext } from 'react';

export type AuthFormSteps = 'phone' | 'code' | 'username' | false;

export type AuthFormContextTypes = {
  userCache: React.MutableRefObject<IAuthLoginPayload>;
  setFormStep: React.Dispatch<React.SetStateAction<AuthFormSteps>>;
  formStep: AuthFormSteps;
  showConfirmCodeModal: boolean;
};

export const AuthFormContext = createContext<AuthFormContextTypes>(null);

export const useAuthFormContext = () => useContext(AuthFormContext);
