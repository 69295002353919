import React, { useCallback } from 'react';

import { AuthFormProps } from 'components/Auth/AuthForm/AuthForm';
import { AuthModalContentLG } from '../AuthModal';
import { AuthModal } from '../AuthModal/AuthModal';
import { AuthConfirmCodeStep } from './AuthConfirmCodeStep';
import { AuthEmail } from './AuthEmail';
import { useAuthFormContext } from './AuthFormContext';
import { AuthPhoneStep } from './AuthPhoneStep';

export const AuthFormContent = React.memo(
  ({
    hasConfirmCodeModal = true,
    hasPhoneModal,
    size,
    showPhoneModal,
    showUsernameForm,
    showBackCodeStep = true,
    onClosePhoneModal,
    showBackButton = false,
    hidePhoneStepOnConfirmCode = true,
    showClose = true,
    ...props
  }: AuthFormProps) => {
    const { setFormStep, formStep, showConfirmCodeModal } = useAuthFormContext();
    const onBackModal = useCallback(() => setFormStep('phone'), [formStep]);

    const onCloseModal = useCallback(() => {
      onClosePhoneModal && onClosePhoneModal();
      return setFormStep('phone');
    }, [formStep]);

    return (
      <>
        {!hasPhoneModal &&
          (hidePhoneStepOnConfirmCode ? (
            formStep === 'phone' && <AuthPhoneStep {...props} />
          ) : (
            <AuthPhoneStep {...props} />
          ))}

        {!hasConfirmCodeModal && formStep === 'code' && <AuthConfirmCodeStep />}

        {hasPhoneModal && formStep === 'phone' && (
          <AuthModal
            size={size}
            open={showPhoneModal}
            showBack={showBackButton}
            showClose={showClose}
            onClose={onClosePhoneModal}
            hasMobileFullHeight={size === 'lg'}>
            {size === 'sm' ? (
              <AuthPhoneStep {...props} />
            ) : (
              <AuthModalContentLG>
                <AuthPhoneStep {...props} />
              </AuthModalContentLG>
            )}
          </AuthModal>
        )}

        {hasConfirmCodeModal && (
          <AuthModal
            showBack={showBackCodeStep}
            onBack={onBackModal}
            open={showConfirmCodeModal}
            onClose={onCloseModal}
            hasMobileFullHeight={false}>
            <AuthConfirmCodeStep />
          </AuthModal>
        )}

        {showUsernameForm && (
          <AuthModal open showClose={false} hasMobileFullHeight={false}>
            <AuthEmail />
          </AuthModal>
        )}
      </>
    );
  }
);

AuthFormContent.displayName = 'AuthFormContent';
