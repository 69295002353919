import { ITruckOptionsValues } from '@truckmap/common';
import { ModalFooterProps } from 'components/common/Modal/ModalSelectGroup/ModalFooter';
import { useMapControllerContext } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { useBreakpoints } from 'hooks/useUI/useBreakpoints';
import { produce } from 'immer';
import { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { createTripTriggerAtom } from 'recoil/routePlanner/routePlannerAtom';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';
import { userMetasAtom } from 'recoil/user/userMetasAtom';

export function useTruckOptionsModal() {
  const recoilState = useRecoilValue(truckOptionsModalAtom);
  const controllerId = useMapControllerContext();
  const { currentBreakpoint } = useBreakpoints();
  const isMobileVersion = currentBreakpoint.toUpperCase() === 'XS';

  const handleCancel = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        if (!isMobileVersion) {
          reset(truckOptionsModalAtom);
          return;
        }

        set(
          truckOptionsModalAtom,
          produce((draft) => {
            // if some option is active on mobile, we don't close the modal
            draft.modalOpen = !!draft.activeOption;
            draft.activeOption = null;
            draft.activeModalTitle = null;
          })
        );
      },
    [isMobileVersion]
  );

  const resetTruckOptionModal = useRecoilCallback(
    ({ reset }) =>
      () => {
        reset(truckOptionsModalAtom);

        return;
      },
    []
  );

  const handleBackButton = useRecoilCallback(
    ({ set }) =>
      () => {
        set(
          truckOptionsModalAtom,
          produce((draft) => {
            draft.activeOption = null;
            draft.activeModalTitle = null;
          })
        );
      },
    []
  );

  const handleSubmit = useRecoilCallback(
    ({ reset, set }) =>
      (newOptions) => {
        set(userMetasAtom, (currentOptions) => ({
          ...currentOptions,
          ...(newOptions as ITruckOptionsValues)
        }));
        set(createTripTriggerAtom(controllerId), () => Date.now());

        // Back to settings list on mobile
        isMobileVersion && Boolean(recoilState.activeOption)
          ? handleBackButton()
          : reset(truckOptionsModalAtom);
      },
    [isMobileVersion, recoilState.activeOption, handleBackButton]
  );

  const shouldDisableSubmit = useCallback<ModalFooterProps['shouldDisableSubmit']>(
    (formState) => !formState.isDirty || formState.isSubmitting,
    []
  );

  return {
    ...recoilState,
    isMobileVersion,
    handleCancel,
    shouldDisableSubmit,
    handleBackButton,
    handleSubmit,
    resetTruckOptionModal
  };
}
