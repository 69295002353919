import { Popover as HeadlessPopover } from '@headlessui/react';
import { usePopoverPosition } from 'hooks/useUI/usePopoverPosition';
import { makeStyles } from 'lib/makeStyles';
import React, { memo, ReactNode, useMemo, useRef } from 'react';

import { DotsVerticalButton } from '../Button';
import { PopoverOpenTransition } from '../PopoverForm/PopoverOpenTransition';
import { PopoverListOptions, PopoverListOptionsProps } from './PopoverListOptions';

export type PopoverProps = {
  renderButton?: (open: boolean) => ReactNode;
  buttonClassName?: string;
  closeOnMouseLeave?: boolean;
} & PopoverStyleProps &
  PopoverListOptionsProps &
  ChildrenProp;

export type PopoverStyleProps = {
  drawerClassName?: string;
  position?: 'topLeft' | 'topRight' | 'right' | 'left' | 'bottomLeft' | 'bottomRight';
};

const useStyles = makeStyles<PopoverStyleProps>()({
  root: ['relative w-min'],
  drawer: ({ drawerClassName, position }: PopoverStyleProps) => [
    'absolute',
    {
      'right-0': ['bottomRight', 'topRight', 'right'].includes(position)
    },
    {
      'py-2': !drawerClassName && position !== 'right' && position !== 'left',
      'px-2': (!drawerClassName && position === 'right') || position === 'left'
    },
    drawerClassName
  ]
});

const Popover: React.FC<PopoverProps> = memo(
  ({
    children,
    closeOnMouseLeave = false,
    drawerClassName,
    renderButton,
    options,
    position = 'bottomLeft',
    buttonClassName,
    ...props
  }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const drawerRef = useRef<HTMLDivElement>(null);
    const { translateX, translateY } = usePopoverPosition({ buttonRef, drawerRef, position });
    const styles = useStyles({ drawerClassName, position });

    return useMemo(
      () => (
        <HeadlessPopover className={styles.root}>
          {({ open, close }) => (
            <>
              <HeadlessPopover.Button ref={buttonRef} className={buttonClassName}>
                {renderButton ? (
                  renderButton(open)
                ) : (
                  <DotsVerticalButton as="div" hasBackground={open} />
                )}
              </HeadlessPopover.Button>
              <PopoverOpenTransition open={open}>
                <HeadlessPopover.Panel
                  ref={drawerRef}
                  className={styles.drawer}
                  style={{
                    transform: `translate(${translateX}px, ${translateY}px)`
                  }}
                  onMouseLeave={closeOnMouseLeave && (close as () => void)}
                  static>
                  {options && <PopoverListOptions options={options} onClose={close} {...props} />}
                  {children}
                </HeadlessPopover.Panel>
              </PopoverOpenTransition>
            </>
          )}
        </HeadlessPopover>
      ),
      [translateX, translateY, props]
    );
  }
);

Popover.displayName = 'Popover';

export { Popover };
