import { authLogin, setAccessToken } from '@truckmap/common';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { Button } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { TextInput } from 'components/common/Input';
import { Translation } from 'components/common/Translation';
import { HeadingText, MetaText, SmallText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import useDeviceSecret from 'hooks/useAuth/useDeviceSecret';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { pingEvent } from 'lib/analytics/event';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAuthFormStyles } from 'components/Auth/AuthPage';
import { useAuthFormContext } from './AuthFormContext';

const phoneInputName = 'auth_phone';

export type AuthPhoneStepProps = {
  append?: React.ReactNode;
  hasDefaultHeading?: boolean;
  justify?: 'center' | 'start';
  label?: string | React.ReactNode;
};

export const AuthPhoneStep = ({
  append,
  label,
  justify = 'center',
  hasDefaultHeading = true
}: AuthPhoneStepProps) => {
  const styles = useAuthFormStyles({ justify });
  const { handleSubmit, formState, control } = useForm();
  const { t } = useTranslation();
  const { userCache, setFormStep } = useAuthFormContext();
  const onSubmitPhone = useCallback(
    (params) => {
      pingEvent('AUTH_PHONE_NUMBER_SUBMIT');
      authLogin({ phone: params[phoneInputName] })
        .then((user) => {
          pingEvent('AUTH_PHONE_NUMBER_SUBMIT_SUCCESS');
          // avoid component extra update
          userCache.current = user;

          setAccessToken(user.token.token);
          setFormStep('code');
        })
        .catch(() => {
          pingEvent('AUTH_PHONE_NUMBER_SUBMIT_ERROR');
          globalAlert({
            intent: 'danger',
            message: t('SIGNUP_CODE_ERROR'),
            isSmall: true
          });
        });
    },
    [setFormStep]
  );

  return (
    <form onSubmit={handleSubmit(onSubmitPhone)}>
      {React.createElement(() => {
        const { ready: deviceReady } = useDeviceSecret();

        return (
          <div className={styles.root}>
            <PanelItem column>
              {append
                ? append
                : hasDefaultHeading && (
                    <PanelItem paddingY={false}>
                      <HeroIcon className={styles.icon} type="outline" name="user-circle" />
                      <HeadingText bold size="XS" as="h3">
                        <Translation textKey="AUTH_HEADING" />
                      </HeadingText>
                    </PanelItem>
                  )}
              <PanelItem fullWidth paddingY={false} column justify={justify}>
                {label ? (
                  label
                ) : (
                  <PanelItem paddingY={false} align="center">
                    <MetaText className={styles.textJustify} as="label" htmlFor={phoneInputName}>
                      <Translation textKey="AUTH_LABEL" />
                    </MetaText>
                  </PanelItem>
                )}
                <Controller
                  render={({ field }) => (
                    <TextInput
                      fullWidth
                      id={phoneInputName}
                      className={styles.input}
                      {...field}
                      mask="+1 (999) 999-9999"
                      placeholder="+1 (___) ___-____"
                    />
                  )}
                  name={phoneInputName}
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                />
              </PanelItem>
              <PanelItem column>
                <Button
                  isPrimary
                  fullWidth
                  type="submit"
                  disabled={!deviceReady || formState.isSubmitting}>
                  <Translation textKey="BUTTON_SUBMIT_LOGIN" />
                </Button>
                <SmallText className={styles.textJustify} dark>
                  <Translation textKey="LOGIN_SIGNUP_NOTICE" />
                </SmallText>
              </PanelItem>
            </PanelItem>
          </div>
        );
      })}
    </form>
  );
};

AuthPhoneStep.displayName = 'AuthPhoneStep';
