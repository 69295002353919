import { AuthForm } from 'components/Auth/AuthForm';
import { AuthPhoneStepProps } from 'components/Auth/AuthForm/AuthPhoneStep';
import { Card } from 'components/common/Card';
import { HeroIcon } from 'components/common/HeroIcon';
import { Translation } from 'components/common/Translation';
import { BodyText, HeadingText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { Page } from 'components/Page';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';

import { PageContextTypes } from 'types/pages/PageContextTypes';

export const useStyles = makeStyles({
  root: [
    'h-full w-full',

    'flex-grow',
    'pt-24 md:pt-4 lg:py-24',
    'flex flex-col flex-nowrap xl:flex-row xl:flex-wrap',
    'space-between justify-center items-center xl:items-start',
    'overflow-hidden'
  ],
  bodyText: 'max-w-lg pt-2',
  textOuter: ['max-w-lg xl:max-w-2xl px-2'],
  formOuter: 'max-w-lg px-2 py-6 lg:py-0',
  formHeading: 'text-left w-full',
  formLabel: 'text-left w-full mt-4',
  padding: 'p-4'
});

export const useAuthFormStyles = makeStyles<AuthPhoneStepProps>()({
  root: ['flex flex-col items-center w-full px-11.5'],
  icon: ['w-6 h-6', 'shrink-0', 'bg-tmBlue bg-no-repeat'],
  inputContainer: ['w-full'],
  input: ['w-full mt-2', 'placeholder:text-neutralText'],
  textJustify: (props: AuthPhoneStepProps) => [
    'pt-2 w-full',
    {
      'text-center': props?.justify === 'center',
      'text-left': props?.justify === 'start'
    }
  ]
});

export const AuthPage = React.memo((props: PageContextTypes) => {
  const styles = useStyles();
  const authStyles = useAuthFormStyles();
  return (
    <Page {...props}>
      <div className={styles.root}>
        <div className={styles.textOuter}>
          <HeadingText as="h1" size="XL">
            <Translation textKey="AUTH_PAGE_HEADING" />
          </HeadingText>
          <BodyText as="h2" large className={styles.bodyText}>
            <Translation textKey="AUTH_PAGE_SUBHEADING" />
          </BodyText>
        </div>
        <div className={styles.formOuter}>
          <Card>
            <div className={styles.padding}>
              <AuthForm
                justify="start"
                hasDefaultHeading={false}
                hidePhoneStepOnConfirmCode={false}
                showBackCodeStep={false}
                label={
                  <PanelItem paddingY={false} fullWidth column>
                    <PanelItem paddingY={false} fullWidth>
                      <HeroIcon className={authStyles.icon} type="outline" name="user-circle" />
                      <HeadingText as="h3" className={styles.formHeading} size="XS">
                        <Translation textKey="AUTH_HEADING" />
                      </HeadingText>
                    </PanelItem>
                    <BodyText className={styles.formLabel} bold>
                      <Translation textKey="AUTH_LABEL" />
                    </BodyText>
                  </PanelItem>
                }
                hasConfirmCodeModal
              />
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
});

AuthPage.displayName = 'AuthPage';
