'use client';
import { authVerify, authVerifyResend, TruckmapApiClientError } from '@truckmap/common';
import { globalAlert } from 'components/common/Alert/AlertGlobalHotToast';
import { Button } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { OTPInput } from 'components/common/Input/OTPInput';
import { Translation } from 'components/common/Translation';
import { HeadingText, MetaText, SmallText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useFormatPhoneNumber } from 'hooks/useFormat/useFormatPhoneNumber';
import Cookies from 'js-cookie';
import { pingEvent } from 'lib/analytics/event';
import { makeStyles } from 'lib/makeStyles';
import { PublicURLModel } from 'models/PublicURLModel';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { userAtom } from 'recoil/user';
import { truckMapConfig } from 'truckMapConfig';

import { useAuthFormStyles } from 'components/Auth/AuthPage';
import { useAuthFormContext } from './AuthFormContext';

const phoneVerifyCodeInput = 'signup_login_phone_code';

const useStyles = makeStyles({
  root: ['text-center', 'flex flex-col items-center', 'md:max-w-screen-xs', 'px-10 md:px-2'],
  modal: ['w-full md:max-w-sm'],
  // max width on input for Safari
  otpInput: ['appearance-none spin-button-none', 'mt-5', 'max-w-[50px]'],
  label: 'my-1'
});

const AuthConfirmCodeStep = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const sharedStyles = useAuthFormStyles();
  const router = useRouter();
  const { control, watch } = useForm();
  const codeValue = watch(phoneVerifyCodeInput);
  const { userCache, setFormStep } = useAuthFormContext();
  const setUserState = useSetRecoilState(userAtom);
  const phoneNumber = useFormatPhoneNumber(userCache.current?.user?.phone);
  const submitPhoneCode = useCallback((code) => {
    pingEvent('AUTH_CONFIRM_CODE');
    authVerify({ code })
      .then(() => {
        pingEvent('AUTH_CONFIRM_CODE_SUCCESS');
        const cookieExpire = new Date();

        // 7 days
        cookieExpire.setDate(cookieExpire.getDate() + 7);
        Cookies.set(truckMapConfig.cookies.accessToken, userCache.current.token.token, {
          expires: cookieExpire
        });
        setUserState(userCache.current);
        setFormStep(false);

        router.push(PublicURLModel.ROUTE);
      })
      .catch((e: TruckmapApiClientError) => {
        pingEvent('AUTH_CONFIRM_CODE_ERROR');
        globalAlert({
          intent: 'danger',
          message: t('SIGNUP_CODE_ERROR'),
          description: e?.message,
          isSmall: true
        });
      });
  }, []);

  const resendCode = useCallback(
    () =>
      authVerifyResend()
        .then(() => {
          pingEvent('AUTH_CONFIRM_CODE_RESEND');
          globalAlert({
            intent: 'success',
            message: t('SIGNUP_AUTH_CODE_RESENT'),
            isSmall: true
          });
        })
        .catch((e) => {
          pingEvent('AUTH_CONFIRM_CODE_RESEND_ERROR');
          console.error('error', e);
          globalAlert({
            intent: 'danger',
            message: t('SIGNUP_CODE_ERROR'),
            isSmall: true
          });
        }),
    []
  );

  useEffect(() => {
    if (codeValue?.length === 5) {
      submitPhoneCode(codeValue);
    }
  }, [codeValue]);

  return (
    <form className={styles.modal}>
      <PanelItem column>
        <div className={styles.root}>
          <PanelItem paddingY={false}>
            <HeroIcon className={sharedStyles.icon} type="outline" name="shield-check" />
            <HeadingText bold size="XS" as="h3">
              <Translation textKey="TITLE_LOGIN_SIGNUP_CODE" />
            </HeadingText>
          </PanelItem>
          <PanelItem column>
            <PanelItem justify="center" column className={styles.label}>
              <MetaText as="label" htmlFor={phoneVerifyCodeInput}>
                <Translation textKey="SIGNUP_CODE_LABEL" />
              </MetaText>
              <MetaText bold>{`${phoneNumber}`}</MetaText>
            </PanelItem>
            <Controller
              render={({ field }) => <OTPInput {...field} inputClassName={styles.otpInput} />}
              name={phoneVerifyCodeInput}
              control={control}
              rules={{ required: true, maxLength: 5, minLength: 5 }}
              defaultValue=""
            />
          </PanelItem>
        </div>
        <PanelItem justify="center">
          <SmallText>
            <Translation textKey="SIGNUP_NO_CODE" />{' '}
            <Button borderless onClick={resendCode}>
              <Translation textKey="BUTTON_RESEND_CODE" />
            </Button>
          </SmallText>
        </PanelItem>
      </PanelItem>
    </form>
  );
};

AuthConfirmCodeStep.displayName = 'AuthConfirmCodeStep';

export { AuthConfirmCodeStep };
