'use client';
import { TruckOptionsContent } from 'components/TruckOptions/TruckOptionsContent';
import { BackButton } from 'components/common/Button';
import { HeroIcon } from 'components/common/HeroIcon';
import { Modal } from 'components/common/Modal';
import { ModalTitleWithIcon } from 'components/common/Modal/ModalContent';
import { ModalFooter } from 'components/common/Modal/ModalSelectGroup/ModalFooter';
import { SkeletonGrid } from 'components/common/Skeleton/SkeletonGrid';
import { OpacityTransition } from 'components/common/Transitions/OpacityTransition';
import { Translation } from 'components/common/Translation';
import { MetaText } from 'components/common/Typography';
import { PanelPadding } from 'components/layout/Panel/PanelPadding';
import { useTruckOptionsModal } from 'hooks/useTruckOptions/useTruckOptionsModal';
import { makeStyles } from 'lib/makeStyles';
import React, { Suspense, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { truckOptionsModalAtom } from 'recoil/truckOptions/truckOptionsModalAtom';
import { useFlexColumnLayoutStyles } from 'styles/commonStyles';

const useTruckOptionsModalStyles = makeStyles({
  icon: ['w-5 h-5', 'shrink-0', 'bg-neutralIcon bg-no-repeat'],
  backButton: ['sm:hidden', 'p-1'],
  closeButton: ['absolute top-1 right-2.5']
});

export const TruckOptionsModal: React.ComponentType = () => {
  const {
    isMobileVersion,
    activeModalTitle,
    handleBackButton,
    handleSubmit,
    handleCancel,
    modalOpen,
    activeOption,
    shouldDisableSubmit,
    resetTruckOptionModal
  } = useTruckOptionsModal();
  const styles = useTruckOptionsModalStyles();
  const flexLayoutStyles = useFlexColumnLayoutStyles({ disableYSpacing: true });
  const recoilState = useRecoilValue(truckOptionsModalAtom);

  const submitLabel = useMemo(
    () =>
      isMobileVersion && Boolean(recoilState.activeOption) ? (
        <Translation textKey="BUTTON_SAVE" />
      ) : (
        <Translation textKey="BUTTON_SUBMIT" />
      ),
    [isMobileVersion, recoilState.activeOption]
  );

  const cancelLabel = useMemo(
    () =>
      isMobileVersion && Boolean(recoilState.activeOption) ? (
        <Translation textKey="BACK" />
      ) : (
        <Translation textKey="BUTTON_CANCEL" />
      ),
    [isMobileVersion, recoilState.activeOption]
  );

  const modalTitleText = (isMobileVersion && activeModalTitle) || (
    <Translation textKey="TRUCK_SETTINGS_BUTTON" />
  );

  return (
    <Modal
      title={
        isMobileVersion && activeModalTitle ? (
          <BackButton
            className={styles.backButton}
            onClick={handleBackButton}
            circle={false}
            iconSize="lg">
            <MetaText as="h2" bold className="px-2">
              {modalTitleText}
            </MetaText>
          </BackButton>
        ) : (
          <ModalTitleWithIcon
            className="pt-1"
            title={modalTitleText}
            icon={
              // Avoid UI jump before icon is rendered
              <div style={{ height: 20, width: 20, marginRight: 8 }}>
                <HeroIcon className={styles.icon} type="solid-mini" name="truck" />
              </div>
            }
          />
        )
      }
      as="form"
      onSubmit={handleSubmit}
      disableOverflowY
      open={modalOpen}
      onClose={resetTruckOptionModal}
      showClose
      closeButtonClassName={styles.closeButton}
      footer={
        <ModalFooter
          submitLabel={submitLabel}
          cancelLabel={cancelLabel}
          shouldDisableSubmit={shouldDisableSubmit}
          onReset={handleCancel}
        />
      }>
      <>
        {/* we only load/request all options when modal is open, this optimization is needed since this component is used in all pages */}
        <Suspense
          fallback={
            <PanelPadding>
              <SkeletonGrid rows={14} columns={2} />
            </PanelPadding>
          }>
          <OpacityTransition show={modalOpen} className={flexLayoutStyles.flexGrow}>
            <TruckOptionsContent activeOption={activeOption} />
          </OpacityTransition>
        </Suspense>
      </>
    </Modal>
  );
};

TruckOptionsModal.displayName = 'TruckOptionsModal';
