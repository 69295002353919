'use client';
import { AuthGate } from 'components/Auth/AuthGate';
import { RuntimeFeatureEnabled } from 'components/FeatureEnabled';
import { memo } from 'react';

export const NavbarAuthGate = memo(() => {
  return (
    <RuntimeFeatureEnabled name="login">
      <AuthGate />
    </RuntimeFeatureEnabled>
  );
});

NavbarAuthGate.displayName = 'NavbarAuthGate';
